import { useState, type FC, type ReactNode } from 'react';
import HouseIconSmall from '../../../../../assets/home-simple.svg?react';
import CarIconSmall from '../../../../../assets/vehicle.svg?react';
import useResponsive from '../../../../../hooks/use-responsive';
import { getDefaultValueString } from '../../../../../questionsForm/components/Question/AddressQuestion/Address.util';
import Button from '../../../../../shared/components/Button/Button';
import Modal from '../../../../../shared/components/Modal/Modal';
import Tabs from '../../../../../shared/components/Tabs/Tabs';
import { type AutoQuoteCoverage, type QuoteAsset } from '../../../../../types/quote.type';
import { getCoveragesGroups } from '../../../../quote/components/AutoQuoteOpportunityPage/AutoQuoteCoverages/AutoQuoteCoverages.util';
import DigitalProfileQuoteCoverages from '../../../../quote/components/DigitalProfileQuotePage/DigitalProfileQuoteCoverages/DigitalProfileQuoteCoverages';
import {
  addressCSS,
  buttonCSS,
  coveragesContainerCSS,
  policyCoveragesTypeCSS,
  smallIconCSS,
  tabsContainerCSS,
  coveragesCSS,
  coveragesInsideTabsCSS,
  autoCoveragesCSS,
  modalCSS,
} from '../../../../quote/components/HomeQuoteOpportunityPage/BundleQuoteCoverages/BundleQuoteCoverages.style';
import { TabActiveType } from '../../../../quote/components/HomeQuoteOpportunityPage/BundleQuoteCoverages/BundleQuoteCoverages.type';
import {
  getBranchNotPolicyCoverages,
  getBranchPolicyCoveragesGroup,
} from '../../../../quote/components/HomeQuoteOpportunityPage/BundleQuoteCoverages/BundleQuoteCoverages.util';
import type { BundleQuoteCoveragesModalProps } from './BundleQuoteCoveragesModal.type';
import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';

const BundleQuoteCoveragesModal: FC<BundleQuoteCoveragesModalProps> = ({
  isOpen,
  homeQuote,
  autoQuote,
  address,
  onModalClose,
}) => {
  const [activeType, setActiveType] = useState<TabActiveType>(TabActiveType.HOME);
  const { isMobile, isTablet } = useResponsive();

  const getAutoCoveragesTabs = (
    coverages: AutoQuoteCoverage[],
    assets: QuoteAsset[]
  ): Array<{ label: ReactNode; content: ReactNode }> => {
    const policyCoveragesGroup = getBranchPolicyCoveragesGroup(coverages);
    const notPolicyCoverages = getBranchNotPolicyCoverages(coverages);
    const coverageGroups = getCoveragesGroups(notPolicyCoverages, assets);

    return [...policyCoveragesGroup, ...coverageGroups].map((cg) => ({
      label: cg.title,
      content: (
        <DigitalProfileQuoteCoverages
          hideTitle
          customCSS={{ ...coveragesInsideTabsCSS, ...autoCoveragesCSS }}
          coverages={cg.coverages.map((c) => ({
            ...c,
            key: cg.assetGid,
            asset_gid: cg.assetGid,
          }))}
        />
      ),
    }));
  };

  const getCoveragesToRender = (): EmotionJSX.Element => {
    switch (activeType) {
      case TabActiveType.AUTO:
        return (
          <Tabs
            customCSS={tabsContainerCSS}
            tabs={getAutoCoveragesTabs(autoQuote.coverages, autoQuote?.assets ?? [])}
          />
        );
      default:
        return (
          <DigitalProfileQuoteCoverages
            customCSS={coveragesCSS}
            coverages={homeQuote.coverages}
            groupTitle={coverageGroupTitle}
          />
        );
    }
  };

  const coverageGroupTitle = (
    <div>
      <div>Home Coverages</div>
      <div css={addressCSS}>{getDefaultValueString(address)}</div>
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      isFullScreen={isMobile || isTablet}
      title={'Policy details'}
      onClose={onModalClose}
      customCSS={modalCSS}
    >
      <div>
        <div css={policyCoveragesTypeCSS}>
          <Button
            customCSS={[buttonCSS(activeType === TabActiveType.HOME)]}
            onClick={() => setActiveType(TabActiveType.HOME)}
          >
            <HouseIconSmall css={smallIconCSS} />
            Home Coverages
          </Button>
          <Button
            customCSS={[buttonCSS(activeType === TabActiveType.AUTO)]}
            onClick={() => setActiveType(TabActiveType.AUTO)}
          >
            <CarIconSmall css={smallIconCSS} />
            Auto Coverages
          </Button>
        </div>

        <div css={coveragesContainerCSS}>{getCoveragesToRender()}</div>
      </div>
    </Modal>
  );
};

export default BundleQuoteCoveragesModal;
