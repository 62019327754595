export const SEGMENT = {
  pages: {
    error: {
      name: 'error',
    },
    scheduleCall: {
      name: 'schedule_call',
    },
    sessionLocked: {
      name: 'session_timeout',
    },
  },
  events: {
    experimentViewed: 'Experiment Viewed',
    quoteSelected: 'Quote Selected',
    pageCompleted: 'Page Completed',
    bindPolicyClicked: 'Bind Policy Clicked',
    liveHelpClicked: 'Live Help Clicked',
    phoneConversationInitiated: 'Phone Conversation Initiated',
    answersSubmitted: 'Answers Submitted',
    conversationSchedulingInitiated: 'Conversation Scheduling Initiated',
    conversationSchedulingFinished: 'Conversation Scheduling Finished',
    scheduleCallTimeSelected: 'Schedule A Call Time Selected',
    recoverySessionFailed: 'Recovery Session Failed',
    recoverySessionSucceed: 'Recovery Session Succeed',
    finalQuoteViewed: 'Final Quote Viewed',
    quoteSavingsViewed: 'Quote Savings Viewed',
    bundleButtonClicked: 'Bundle Button Clicked',
    flowStarted: 'Flow Started',
    allFunnelQuotesOffered: 'All Funnel Quotes Offered',
    allFunnelQuotesSelected: 'All Funnel Quotes Selected',
    discoverMoreDiscountsClicked: 'Discover More Discounts Clicked',
    contactReminderModalShowed: 'Contact Reminder Modal Showed',
    questionViewed: 'Question Viewed',
    noQuoteMessageViewed: 'No Quote Message Viewed',
    finalPriceDiscountViewed: 'Final Price Discount Viewed',
    bindableQuoteReceived: 'Bindable Quote Received',
    fromDigitalProfileToCustomerPortalRedirected: 'From Digital Profile To Customer Portal Redirected',
    seeMyShadowAgentQuoteClicked: 'See My Shadow Agent Quote Clicked',
    shadowAgentRequestIsFinished: 'Shadow Agent Request Is Finished',
    successfullyRecaptchaComplete: 'User Successfully Completes The Captcha',
    recaptchaPuzzleSeen: 'User Saw The Captcha',
    updateQuoteNotificationShown: 'Update Quote Notification Shown',
    updateQuoteClicked: 'Update Quote Clicked',
    additionalDiscountsModalShown: 'Additional Discounts Modal Shown',
    finalizedCheckoutClicked: 'Finalize Checkout Clicked',
    paymentMethodStepCompleted: 'Payment Method Step Completed',
  },
  locations: {
    page: 'page',
    header: 'header',
    footer: 'footer',
  },
};
