import { useState } from 'react';
import Wrapper from '../../../../shared/components/Wrapper/Wrapper';
import QuoteCoverage from '../QuoteCoverage/QuoteCoverage';
import {
  accordionArrowCSS,
  additionalCoveragesArrowCSS,
  additionalCoveragesCSS,
  contentCSS,
  coverageCSS,
  headerCSS,
  imageCSS,
} from './QuoteCoverageGroup.style';
import type { QuoteCoverageGroupProps } from './QuoteCoverageGroup.type';
import type { FC, ReactNode } from 'react';

const ADDITIONAL_COVERAGES = ['loss_assessment', 'ordinance_or_law', 'personal_injury'];

const QuoteCoverageGroup: FC<QuoteCoverageGroupProps> = ({ customCSS, group, isExpandable = true }) => {
  const { assetGid, title, coverages, iconUrl } = group;
  const [isAllCoveragesExpanded, setIsAllCoveragesExpanded] = useState(true);
  const [isAddiotionalCoveragesExpanded, setIsAddiotionalCoveragesExpanded] = useState(false);

  const mainCoverages = coverages.filter((c) => !ADDITIONAL_COVERAGES.includes(c.key));
  const additionalCoverages = coverages.filter((c) => ADDITIONAL_COVERAGES.includes(c.key));

  const onAccordionToggle = (): void => {
    setIsAllCoveragesExpanded((s) => !s);
  };

  const onAdditionalCoveragesToggle = (): void => {
    setIsAddiotionalCoveragesExpanded((s) => !s);
  };

  const accordionProps = {
    'aria-controls': assetGid,
    'aria-expanded': isAllCoveragesExpanded,
    onClick: onAccordionToggle,
  };

  const additionalCoveragesAccordionProps = {
    'aria-controls': assetGid,
    'aria-expanded': isAddiotionalCoveragesExpanded,
    onClick: onAdditionalCoveragesToggle,
  };

  const renderHeader = (): ReactNode => {
    if (!title) {
      return null;
    }

    return isExpandable ? (
      <Wrapper css={headerCSS} tag={'button'} {...accordionProps}>
        {iconUrl && <img css={imageCSS} src={iconUrl} alt="" />}

        <span>{title}</span>
        <div css={accordionArrowCSS(isAllCoveragesExpanded)} />
      </Wrapper>
    ) : (
      <div css={headerCSS}>{title}</div>
    );
  };

  const renderAdditionalCoveragesControl = (): ReactNode => {
    const buttonText = isAddiotionalCoveragesExpanded ? 'Hide additional Coverages' : 'Show additional Coverages';
    if (!additionalCoverages.length) {
      return null;
    }
    return (
      <Wrapper
        css={additionalCoveragesCSS(isAddiotionalCoveragesExpanded)}
        tag={'button'}
        {...additionalCoveragesAccordionProps}
      >
        {iconUrl && <img css={imageCSS} src={iconUrl} alt="arrow" />}

        <span>{buttonText}</span>
        <div css={additionalCoveragesArrowCSS(!isAddiotionalCoveragesExpanded)} />
      </Wrapper>
    );
  };

  return (
    <div css={customCSS}>
      {renderHeader()}
      {isAllCoveragesExpanded && (
        <div id={assetGid} css={contentCSS}>
          {mainCoverages.map((c) => (
            <QuoteCoverage
              key={c.title}
              customCSS={coverageCSS}
              title={c.title}
              value={c.value}
              description={c.description}
              iconUrl={c.iconUrl}
            />
          ))}
        </div>
      )}
      {renderAdditionalCoveragesControl()}
      {isAddiotionalCoveragesExpanded && (
        <div id={assetGid} css={contentCSS}>
          {additionalCoverages.map((c) => (
            <QuoteCoverage
              key={c.title}
              customCSS={coverageCSS}
              title={c.title}
              value={c.value}
              description={c.description}
              iconUrl={c.iconUrl}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default QuoteCoverageGroup;
