import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCreateQuotesRequest, useLastQuoteRequest } from '../../api/quote';
import { useSession } from '../../api/session';
import { SEGMENT } from '../../constants/analytics';
import usePhoneNumber from '../../hooks/use-phone-number';
import { useSegment } from '../../hooks/use-segment';
import CallAgentRibbon from '../../pages/quote/components/DigitalProfileQuotePage/CallAgentRibbon/CallAgentRibbon';
import DigitalProfileNoQuotesContent from '../../pages/quote/components/DigitalProfileQuotePage/DigitalProfileNoQuotesContent/DigitalProfileNoQuotesContent';
import { NoQuoteMessageReason } from '../../pages/quote/components/DigitalProfileQuotePage/DigitalProfileQuotePage.util';
import { containerCSS } from './PolicyHolderDigitalProfileQuotePage.style';
import type { DigitalProfileQuote } from '../../types/quote.type';
import type { DefaultLocation } from '../../types/route-params.type';
import type { FC } from 'react';

const PolicyHolderDigitalProfileQuotePage: FC = () => {
  const { gid } = useParams() as DefaultLocation;
  const { data: sessionData } = useSession(gid);
  const currentPageKey = sessionData?.session.current_page_key;
  const { mutateAsync: createQuotesRequest } = useCreateQuotesRequest(gid);
  const {
    data: lastQuoteRequestData,
    isFetched: isLastQuoteRequestFetched,
    refetch: refetchLastQuoteRequest,
  } = useLastQuoteRequest<DigitalProfileQuote>(gid);
  const { phoneNumber } = usePhoneNumber();

  const { track } = useSegment();

  useEffect(() => {
    const call = async (): Promise<void> => {
      await createQuotesRequest({ pageKey: currentPageKey });
      refetchLastQuoteRequest();
    };
    if (isLastQuoteRequestFetched && lastQuoteRequestData && !lastQuoteRequestData.quotes_request) {
      call();
    }
  }, [lastQuoteRequestData, createQuotesRequest, isLastQuoteRequestFetched, refetchLastQuoteRequest, currentPageKey]);

  const onPhoneClicked = (): void => {
    track(SEGMENT.events.phoneConversationInitiated, {
      page: currentPageKey,
      location: SEGMENT.locations.page,
    });
  };

  const onScheduleCallClicked = (): void => {
    track(SEGMENT.events.conversationSchedulingInitiated, {
      page: currentPageKey,
    });
  };

  return (
    <>
      <CallAgentRibbon phoneNumber={phoneNumber} onPhoneClick={onPhoneClicked} />
      <div css={containerCSS}>
        <DigitalProfileNoQuotesContent
          noQuotesReason={NoQuoteMessageReason.personPolicyholder}
          onPhoneClicked={onPhoneClicked}
          onScheduleCallClick={onScheduleCallClicked}
        />
      </div>
    </>
  );
};

export default PolicyHolderDigitalProfileQuotePage;
