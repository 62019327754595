import { useState } from 'react';

import SCHEMA_KEYS from '../../../../../constants/schema-hardcoded-keys';
import SelectQuestion from '../../../../../questionsForm/components/Question/SelectQuestion/SelectQuestion';
import Wrapper from '../../../../../shared/components/Wrapper/Wrapper';
import QuoteCoverage from '../../QuoteCoverage/QuoteCoverage';
import {
  accordionArrowCSS,
  contentCSS,
  coverageCSS,
  headerCSS,
  imageCSS,
} from '../../QuoteCoverageGroup/QuoteCoverageGroup.style';
import { descriptionCSS } from '../QuoteOptionalCoverage/QuoteOptionalCoverage.style';
import type { QuoteCoverageGroupProps, QuoteCoverageView } from '../../QuoteCoverageGroup/QuoteCoverageGroup.type';

import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import type { FC, ReactNode } from 'react';

const AutoQuoteCoverageGroup: FC<QuoteCoverageGroupProps> = ({ customCSS, group, isExpandable = true, assets }) => {
  const { assetGid, title, coverages, iconUrl } = group;
  const [isExpanded, setIsExpanded] = useState(true);

  // TODO find more elegant and meaningful solutions to map coverages
  const coveragesToRender = coverages.filter((coverage) => {
    return ![SCHEMA_KEYS.collision, SCHEMA_KEYS.comprehensive].includes(coverage?.key as string);
  });

  const coveragesToChange = coverages.filter((coverage) => {
    return [SCHEMA_KEYS.collision, SCHEMA_KEYS.comprehensive].includes(coverage?.key as string);
  });

  const onToggle = (): void => {
    setIsExpanded((s) => !s);
  };

  const accordionProps = {
    'aria-controls': assetGid,
    'aria-expanded': isExpanded,
    onClick: onToggle,
  };

  const renderHeader = (): ReactNode => {
    if (!title) {
      return null;
    }

    return isExpandable ? (
      <Wrapper css={headerCSS} tag={'button'} {...accordionProps}>
        {iconUrl && <img css={imageCSS} src={iconUrl} alt="" />}

        <span>{title}</span>
        <div css={accordionArrowCSS(isExpanded)} />
      </Wrapper>
    ) : (
      <div css={headerCSS}>{title}</div>
    );
  };

  const getCustomCoveragesToRender = (c: QuoteCoverageView): EmotionJSX.Element => {
    const currentAsset = assets?.find((asset) => asset.gid === c.asset_gid);
    const optionsValue = currentAsset?.coverage_options?.find((option) => option.key === c.key);

    if (optionsValue?.values?.length) {
      return (
        <div css={coverageCSS} key={c.title}>
          <SelectQuestion
            dense
            questionKey={`${SCHEMA_KEYS.autoPolicyPrefix}${c.key}` + '_' + `${c.asset_gid}`}
            label={c.title}
            variants={optionsValue?.values}
          />
          {c.description && <div css={descriptionCSS}>{c.description}</div>}
        </div>
      );
    }

    return (
      <QuoteCoverage
        key={c.title}
        customCSS={coverageCSS}
        title={c.title}
        value={c.value}
        description={c.description}
        iconUrl={c.iconUrl}
      />
    );
  };

  return (
    <div css={customCSS}>
      {renderHeader()}
      {isExpanded && (
        <div id={assetGid} css={contentCSS}>
          {coveragesToChange.map((c) => getCustomCoveragesToRender(c))}
          {coveragesToRender.map((c) => (
            <QuoteCoverage
              key={c.title}
              customCSS={coverageCSS}
              title={c.title}
              value={c.value}
              description={c.description}
              iconUrl={c.iconUrl}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default AutoQuoteCoverageGroup;
