import type { PolicyType } from './policy.type';
import type { QuestionVariant } from './question.type';

export const HOME_POLICY_TYPES: readonly string[] = ['home', 'condo', 'fire', 'renters', 'manufactured_or_mobile_home'];

export enum QuoteAssetType {
  Vehicle = 'Vehicle',
  Home = 'Home',
}

export interface CoverageOption {
  key: string;
  values: QuestionVariant[];
}

export interface QuoteAsset {
  gid: string;
  type: QuoteAssetType;
  name: string;
  icon_url?: string;
  coverage_options?: CoverageOption[];
}

export interface QuoteCoverage {
  key: string;
  title: string;
  description?: string;
  value?: string;
  icon_url?: string;
}

export interface PaymentOptions {
  downpayment: number;
  subsequent_payment: number;
  base_premium: number;
  full_term_premium: number;
}

export interface Quote {
  gid: string;
  premium: string;
  policy_type: PolicyType;
  policy_term: number;
  policy_effective_date: string;
  carrier_key: string;
  carrier_logo_url: string;
  carrier_name: string;
  multi_policy_discount_premium?: string | null;
  bindable: boolean;
  payment_options: PaymentOptions[] | [];
}

export interface HomeInitialQuote extends Omit<HomeQuote, 'discounts' | 'multi_policy_discount_premium'> {
  carrier_name: string;
}

export interface HomeQuoteCoverage extends QuoteCoverage {}

export interface AutoQuoteCoverage extends QuoteCoverage {
  asset_gid: string | null;
}

// TODO: Refactor after new discounts applied
export interface HomeQuoteDiscounts {
  key?: string;
  title: string;
  description?: string;
  icon_url?: string | null;
}

export interface AutoQuote extends Quote {
  coverages: AutoQuoteCoverage[];
  assets: QuoteAsset[];
}

export interface HomeQuote extends Quote {
  deductible: number;
  coverages: HomeQuoteCoverage[];
  discounts: HomeQuoteDiscounts[];
}
export interface DigitalProfileQuote extends Quote {
  deductible?: number;
  coverages: HomeQuoteCoverage[] | AutoQuoteCoverage[];
  assets?: QuoteAsset[];
}

export type QuoteType = AutoQuote | HomeQuote | DigitalProfileQuote;

export interface QuoteResponse<T = QuoteType> {
  quote: T;
}

export interface QuotesGroup<T = QuoteType> {
  quotes: T[];
  total_premium: string;
  total_premium_with_multi_policy_discount?: string;
  multi_policy_discount?: boolean;
}

export interface QuoteRequest<T = QuoteType> {
  gid: string;
  started_at: string;
  finished_at: string | null;
  groups: Array<QuotesGroup<T>>;
  page_key: string | null;
  slow_quotes_in_progress: boolean | null;
}

export interface QuoteRequestResponse<T = QuoteType> {
  quotes_request: QuoteRequest<T> | null;
}

export interface QuotesRequestsListResponse<T = QuoteType> {
  quotes_requests: Array<QuoteRequest<T>>;
}
